import {
  request
} from '@/utils/request'
export default {
  // 列表
  search(data) {
    return request({
      url: `/question/paper/stuPaperList`,
      method: 'get',
      params: data
    })
  },
  // 显示问卷题目
  getList(data) {
    return request({
      url: `/question/paper/getStuPaperVo`,
      method: 'get',
      params: data
    })
  },
  // 提交问卷答案
  save(data) {
    return request({
      url: `/question/answer/stuSavePaperAnswer`,
      method: 'post',
      // header: {
      //     'content-type': 'application/json'
      // },
      data
    })
  },
  // 显示已评未评
  getRataList(data) {
    return request({
      url: `/question/paper/getStuPaperRatabledsList`,
      method: 'get',
      params: data
    })
  },
  getAllRataList(data) {
    return request({
      url: `/question/paper/getStuPaperAllRatabledsList`,
      method: 'get',
      params: data
    })
  },
  getAnalyNum(data) {
    return request({
      url: `/question/paper/stuPaperAnalysisNum`,
      method: 'get',
      params: data
    })
  }
}