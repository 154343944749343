<template>
  <div class="wrapper">
    <nav-top :nav="nav"></nav-top>
    <!-- 搜索 -->
    <div class="search a-flex-baseline">
      <el-form
        class="search-form"
        ref="searchForm"
        :inline="true"
        :model="inquire"
      >
        <el-form-item prop="title" label="问卷标题：">
          <el-input
          v-model.trim="inquire.title"
          placeholder="请输入标题"
          clearable
          class="input radious10"
        ></el-input>
        </el-form-item>
        <el-form-item prop="sdate" label="日期范围：">
          <el-date-picker
            class="date"
            v-model="sdate"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" plain @click="resetForm('searchForm')">重置</el-button> -->
          <el-button class="button" type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container" v-if="result.length">
      <el-row :gutter="20">
        <el-col :span="12" v-for="(item, index) in result" :key="index">
          <div class="card">
            <el-tooltip effect="light" :content="item.title" placement="top-start" v-if="item.title.length >= 26">
              <h4>{{item.title}}</h4>
            </el-tooltip>
            <h4 v-else>{{item.title}}</h4>
            <div class="main">
              <div class="code-box">
                <vue-qr :logo-src="logoSrc" :size="191" :margin="0" :auto-color="true" :dot-scale="1" :text="appSrc + `${item.code}`" />
              </div>
              <ul>
                <li>评价时间：
                  <span>{{ $moment(item.startDate).format('YYYY.MM.DD HH:mm') }} -{{ $moment(item.endDate).format('YYYY.MM.DD HH:mm') }}</span>
                </li>
                <li>完成情况：
                  <span>{{ item.answerNum }} / {{ item.retabledsNum }}</span>
                </li>
                <li>状态：
                  <span :class="item.status | className">{{item.status==1?'完成':'未完成'}}</span>
                </li>
              </ul>
              <el-button class="button" type="primary" :plain="item.status==1" @click="goDetail(item)">{{item.status==1?'问卷详情':'进入问卷'}}</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-pagination
        :page-size="inquire.limit"
        :current-page="inquire.page"
        layout="total,prev, pager, next, jumper"
        :total="total"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div v-else class="b-blank">
      <div class="img"></div>
      <p>暂无内容</p>
    </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr';
import NavTop from '@/components/NavTop.vue';
import api from '@/api/question';
export default {
  components: {
    NavTop,
    VueQr,
  },
  data() {
    return {
      inquire: {
        startDate: '',
        endDate: '',
        title: '',
        page: 1,
        limit: 10,
      },
      sdate: '',
      total: 0,
      pages: 1,
      result: [], //接口数据
      logoSrc: '',
      appSrc: process.env.VUE_APP_BASE_API + '/filter/view/real/wechat?code=',
      list: {}, //接口数据
      ratabledId: '',
      nav: [
        {
          name: '收集中问卷',
          num: 0,
          img: require('@/assets/img/base/con_pjb.png'),
          status: 0,
        },
        {
          name: '已完成',
          num: 0,
          img: require('@/assets/img/base/con_ywc.png'),
          status: 0,
        },
        {
          name: '未完成',
          num: 0,
          img: require('@/assets/img/base/con_wwc.png'),
          status: 0,
        },
        {
          name: '已评价老师',
          num: 0,
          img: require('@/assets/img/base/con_gpjls.png'),
          status: 1,
        },
        {
          name: '待评价老师',
          num: 0,
          img: require('@/assets/img/base/con_dpjls.png'),
          status: 1,
        },
      ],
      show: true,
    };
  },
  created() {
    let schoolId = localStorage.getItem('ss-schoolId')
    this.appSrc = process.env.VUE_APP_BASE_API + '/filter/view/real/wechat?schoolId='+schoolId+'&code='
  },
  mounted() {
    this.loadmovies();
    this.fetchData();
  },
  filters: {
    className(val) {
      if (val == 0) {
        return 'nostate';
      } else {
        return 'over';
      }
    },
  },
  methods: {
    search() {
      this.inquire.page = 1;
      this.inquire.limit = 10;
      this.fetchData();
    },
    fetchData() {
      api.search(this.inquire).then(response => {
        if (response.success) {
          this.list = response.result;
          this.result = response.result.records;
          this.total = response.result.total;
          this.pages = response.result.pages;
        } else {
          this.$message({
            message: '请求异常',
            type: 'warning',
          })
        }
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      });
    },
    loadmovies() {
      api.getAnalyNum().then(res => {
        res.result.forEach((el,index) => {
          this.nav[index].num = el;
        });
      });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    //   this.inquire.startDate = '';
    //   this.inquire.endDate = '';
    // },
    goDetail(item) {
      // 无被评价人
      if (item.retabledsNum === 0) {
        this.$router.push({
          name: 'qdetail',
          query: {
            paperId: item.id,
            ratabledId: '',
          },
        });
      } else {
        this.$router.push({
          name: 'qlist',
          query: {
            paperId: item.id
          },
        });
      }
    },
    dateChange(val) {
      if(val){
        this.inquire.startDate = val[0];
        this.inquire.endDate = val[1];
      }else{
        this.inquire.startDate = "";
        this.inquire.endDate = "";
      }
    },
    handleCurrentChange(val) {
      this.inquire.page = val;
      this.fetchData();
    }
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  min-height: 800px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.el-form{
  margin-top: 20px;
  .date{
    width: 348px;
  }
}

.card {
  margin-bottom: 18px;
  border: 2px solid #f6f6fc;
  box-shadow: 8px 14px 18px 0px rgba(243, 245, 255, 0.72);
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  h4 {
    font-weight: normal;
    font-size: 20px;
    color: #343a52;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .main{
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    .code-box{
      width: 90px;
      height: 90px;
      margin-right: 20px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    ul {
      flex: 1;
      li {
        font-size: 16px;
        color: #a2a4aa;
        line-height: 2;
        span {
          color: #42475e;
        }
        span.nostate {
          color: #4691f3;
        }
        span.doing {
          color: #05b776;
        }
        span.over {
          color: #ff8400;
        }
      }
    }
    .el-button {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
}
.el-pagination {
  text-align: right;
}
</style>
